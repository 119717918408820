<template>
  <span v-if="profile.role != 'User'">
    <v-card class="mt-3 pa-3">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-select
              label="Session"
              :items="sessions"
              return-object
              item-text="name"
              v-model="selectedSession"
              @change="onSelectedSession"
            ></v-select>
          </v-col>
          <v-col v-if="selectedSession">
            <v-data-table :headers="headers" :items="periods">
              <template v-slot:item.start="{ item }">
                <span v-text="new Date(item.start).toLocaleString()"></span>
              </template>
              <template v-slot:item.end="{ item }">
                <span v-text="new Date(item.end).toLocaleString()"></span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn color="secondary" icon @click="openEditDialog(item)">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn color="error" icon @click="deletePeriod(item)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-col cols="12" class="pa-3 d-flex justify-end" v-if="selectedSession">
        <v-btn
          color="primary"
          fab
          class="ml-3"
          @click="periodCreationDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-card>
    <v-dialog
      v-model="periodEditDialog"
      scrollable
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">Modifier la periode</v-card-title>
        <v-card-text>
          <v-text-field
            label="Nom de la periode"
            v-model="periodEditName"
          ></v-text-field>
          <v-text-field
            type="datetime-local"
            label="Debut de la periode"
            v-model="periodEditStart"
          ></v-text-field>
          <v-text-field
            type="datetime-local"
            label="Fin de la periode"
            v-model="periodEditEnd"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="periodEditDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="editPeriod">Modifier</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="periodCreationDialog"
      scrollable
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">Ajouter une periode</v-card-title>
        <v-card-text>
          <v-text-field
            label="Nom de la periode"
            v-model="periodCreationName"
          ></v-text-field>
          <v-text-field
            type="datetime-local"
            label="Debut de la periode"
            v-model="periodCreationStart"
          ></v-text-field>
          <v-text-field
            type="datetime-local"
            label="Fin de la periode"
            v-model="periodCreationEnd"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="periodCreationDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="createPeriod">Ajouter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

import Session from "@/classes/Session.js";
import Profile from "@/classes/Profile.js";
import Period from "@/classes/Period.js";

export default {
  name: "Periods",
  props: ["user", "profile"],
  setup() {
    return {
      // deltaTime: 60*60*1000,
      // deltaTime: 60 * 60 * 2000,
    };
  },
  data() {
    return {
      unsub: [],
      periodsUnsub: [],
      sessions: [],
      selectedSession: null,
      periods: [],
      headers: [
        { text: "Nom", value: "name" },
        { text: "Debut", value: "start" },
        { text: "Fin", value: "end" },
        { text: "", value: "actions", sortable: false, align: "right" },
      ],

      periodEditDialog: false,
      periodEditSelected: null,
      periodEditName: "",
      periodEditStart: "",
      periodEditEnd: "",

      periodCreationDialog: false,
      periodCreationName: "",
      periodCreationStart: "",
      periodCreationEnd: "",
    };
  },
  created() {
    this.unsub.push(
      Session.listenAll((sessions) => {
        sessions.sort((a, b) => b.date - a.date);
        let tmp_sessions = [];
        sessions.forEach((session) => {
          if (!session.isBase) {
            tmp_sessions.push(session);
          }
        });
        this.sessions = tmp_sessions;
      })
    );
  },
  methods: {
    onSelectedSession() {
      if (this.selectedSession) {
        this.periodsUnsub.forEach((periodUnsub) => {
          if (typeof periodUnsub == "function") {
            periodUnsub();
          }
        });

        this.periodUnsub = [];

        this.periodsUnsub.push(
          Period.listenBySession(this.selectedSession.id, (periods) => {
            if (periods) {
              this.periods = periods;
              this.periods.sort((a, b) => {
                return a.end - b.end;
              });
              this.$forceUpdate();
            }
          })
        );
      }
    },
    openEditDialog(item) {
      this.periodEditSelected = item;
      this.periodEditName = item.name;
      // this.periodEditStart = new Date(item.start + this.deltaTime)
      //   .toISOString()
      //   .slice(0, 16);

      let startTime = new Date(item.start);
      let offSetInMilliSeconds = startTime.getTimezoneOffset() * 60 * 1000;

      this.periodEditStart = new Date(startTime - offSetInMilliSeconds)
        .toISOString()
        .slice(0, 16);

      // this.periodEditEnd = new Date(item.end + this.deltaTime)
      //   .toISOString()
      //   .slice(0, 16);

      let endTime = new Date(item.end);
      let offSetEndInMilliSeconds = endTime.getTimezoneOffset() * 60 * 1000;

      this.periodEditEnd = new Date(endTime - offSetEndInMilliSeconds)
        .toISOString()
        .slice(0, 16);
      this.periodEditDialog = true;
    },
    async createPeriod() {
      let startTimeStamp = new Date(this.periodCreationStart).getTime();
      let endTimeStamp = new Date(this.periodCreationEnd).getTime();

      let period = new Period(
        null,
        this.periodCreationName,
        this.selectedSession.id,
        startTimeStamp,
        endTimeStamp
      );
      await period.save();
      this.$forceUpdate();

      this.periodCreationDialog = false;
      this.periodCreationName = "";
      this.periodCreationStart = "";
      this.periodCreationEnd = "";
    },
    async editPeriod() {
      let startTimeStamp = new Date(this.periodEditStart).getTime();
      let endTimeStamp = new Date(this.periodEditEnd).getTime();

      this.periodEditSelected.name = this.periodEditName;
      this.periodEditSelected.start = startTimeStamp;
      this.periodEditSelected.end = endTimeStamp;
      await this.periodEditSelected.save();
      this.$forceUpdate();

      this.periodEditDialog = false;
      this.periodEditSelected = null;
      this.periodEditName = "";
      this.periodEditStart = "";
      this.periodEditEnd = "";
    },
    deletePeriod(period) {
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "Voulez vous vraiment supprimer cette periode ?",
        icon: "question",
        confirmButtonText: "Confirmer",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          period.delete();
        }
      });
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
